import { useState } from 'react';
import NftCard from '../components/nftcard';
import {fetchNFTs} from '../utils/fetchNFTs';

import NodeWalletConnect from "@walletconnect/node";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";

const walletConnector = new NodeWalletConnect(
  {
    bridge: "https://bridge.walletconnect.org", // Required
  },
  {
    clientMeta: {
      description: "WalletConnect NodeJS Client",
      url: "https://nodejs.org/en/",
      icons: ["https://nodejs.org/static/images/logo.svg"],
      name: "WalletConnect",
    },
  }
);


  
const Explore = () => {

    const [owner, setOwner] = useState("")
    const [contractAddress, setContractAddress] = useState("")
    const [NFTs, setNFTs] = useState("")

	walletConnector.on("connect", (error, payload) => {
		if (error) {
		throw error;
		}
	
		// Close QR Code Modal
		WalletConnectQRCodeModal.close(
		true // isNode = true
		);
	
		// Get provided accounts and chainId
		const { accounts, chainId } = payload.params[0];
	
		//{fetchNFTs("0xB392Fab5675D65d347AA2F9101b44c434ebF9d03", "0x5AD0aB392b9647D2293361864D4c0d68D52111A3", "0x5F0e4269e4057Bc0f0f665d68f3106e2386D8bf4", setNFTs)}	// TESTING
		{fetchNFTs(accounts[0], "0x5AD0aB392b9647D2293361864D4c0d68D52111A3", "0x5F0e4269e4057Bc0f0f665d68f3106e2386D8bf4", setNFTs)}	// PRODUCTION

	});
	

	function openConnection(){

		if (!walletConnector.connected) {
			// create new session
			walletConnector.createSession().then(() => {
				// get uri for QR Code modal
				const uri = walletConnector.uri;
				// display QR Code modal
				WalletConnectQRCodeModal.open(
					uri,
					() => {
					console.log("QR Code Modal closed");
					},
					true // isNode = true
				);
			});
		}
	  
	}

	function openMetamask() {
		console.log("metamask");
		if(window.ethereum){
			window.ethereum.request({method:'eth_requestAccounts'})
			.then(res=>{
				//{fetchNFTs("0xB392Fab5675D65d347AA2F9101b44c434ebF9d03", "0x5AD0aB392b9647D2293361864D4c0d68D52111A3", "0x5F0e4269e4057Bc0f0f665d68f3106e2386D8bf4", setNFTs)}	// TESTING
				{fetchNFTs(res[0], "0x5AD0aB392b9647D2293361864D4c0d68D52111A3", "0x5F0e4269e4057Bc0f0f665d68f3106e2386D8bf4", "0xbB0522765b782D6699E9050107853b6dF68da329", "0x6Dd0f41BbcC495fCF58C453A03Df8ba58530B264", setNFTs)}	// PRODUCTION
			})
		}else{
			alert("install metamask extension!!")
		}		
	}

	if (walletConnector.connected) {
		walletConnector.killSession();
	}
	let hasNFT = NFTs.length > 0;
    return (
        <div className='alchemy'>
			
			<header className='justify-center'>
				<div className='flex flex-col items-center mb-4 martop'>
					<img className='imm' src="./images/flex-white.png" width="260" ></img>
					 <div className='mb-4 text-white text-center'>
						 <h2 className='text-7xl  font-bold font-body mb-2'>VAULT</h2>
					</div>
				</div>
				<div className='btwhite3'><img src="./images/icon-connect.png" width="200"  alt=""/></div>
				<div><button className='btwhite' onClick={() => openConnection()}><img src="./images/icon-walletconnect.png" width="90"  alt=""/></button></div>
				<div><button className='btwhite2' onClick={() => openMetamask()}><img src="./images/icon-metamask.png" width="90"  alt=""/></button></div>			
			</header>
			
			<section className='flex flex-wrap justify-center'>
				 <div className='flex flex-col items-center mb-2'>
					  <div className='mb-16 text-white text-center'>
						  <img src="./images/mobie-powered.png" ></img>
					 </div>
                    <div className='mb-4 text-white text-center'>
						<h2 className='text-5xl  font-bold font-body mb-10'>Flex NFT Rewards</h2>
						<p>Now that we’ve launched the Flex Vault and completed the “Free watch claiming,” we are shipping all of the watches, and moving into the next phase - Flex NFT Rewards!</p>
						<p>Our goal is to continue rewarding the “Flex Fam” by utilizing the Flex Vault and creating strategic partnerships with awesome companies like Mobie!</p>
						<p>The Mobie app helps you manage and use your cash and crypto from one app. You can send, receive, and exchange cash or crypto instantly. The best part is that you can get up to 8% back by shopping at thousands of your favorite retailers.</p>
						<p>Through our partnership we are able to add tremendous value to the Flex community, while delivering on our promise, and staying within all crypto and bank regulations.</p>
						</div>
				</div>	
			</section>

			<section className='flex flex-wrap justify-center'>
				 <div className='flex flex-col items-center mb-2'>
					 <div className='mb-12 text-white text-center'>
					 <div className='text-center'><img src="./images/divider-pink.png" width="260"></img></div>
					 </div>
				</div>	
			</section>

			<section className='flex flex-wrap justify-center'>
				 <div className='flex flex-col items-center mb-2'>
				 	<div className='mb-6 text-white text-center sectitle'>
						<h2 className='text-3xl  font-bold font-body mb-12'>Through or partnership with Mobie, the Flex community will be given exclusive benefits including, but not limited to:</h2>
						<p>
							<ul>
								<li>Free NFT’s</li>
								<li>Discounts </li>
								<li>Cash back rewards </li>
								<li>Reflections</li>
								<li>Sweepstakes and prizes</li>
								<li>Access to affiliate and referral programs</li>
							</ul>
						</p>
					</div>
				</div>	
			</section>
			
			<section className='flex flex-wrap justify-center'>
				 <div className='flex flex-col items-center mb-2'>
					 <div className='mb-12 text-white text-center'>
					 <div className='text-center'><img src="./images/squiggles.png" width="260"></img></div>
					 </div>
				</div>	
			</section>
			
			<section className='flex flex-wrap justify-center whiterborder'>
				 <div className='flex flex-col items-center mb-6'>
                    <div className='mb-6 text-white text-center sectitle'>
						<h4 className='text-3xl mb-8 font-bold'></h4>
						<h2 className='text-4xl  font-bold font-body mb-12'>Here is how you will get access to the Flex Vault and enter into the Mobie Ecosystem!</h2>
				 	</div>
					  <div className='mb-2 text-white text-center'>
						<h3 className='hdnum'>1</h3>
						<h4 className='text-3xl mb-8 font-bold'>Connect your wallet</h4>
						<div className='flex1'>
							<div className='btwhite1'><button onClick={() => openConnection()}><img src="./images/icon-walletconnect.png" width="60"  alt="WalletConnect"/> WalletConnect</button></div>
							<div className='btwhite1'><button onClick={() => openMetamask()}><img src="./images/icon-metamask.png" width="60"  alt="MetaMask"/> MetaMask</button></div>
						</div>
						<h4 className='text-3xl mb-16 font-bold'></h4>
						<h3 className='hdnum'>2</h3>
						<h4 className='text-3xl mb-16 font-bold'>Verify ownership of your Flex NFT</h4>
						{ hasNFT ? (
			<section className='flex flex-wrap justify-center'>
				
				<div className='mb-16 text-white text-center'><h2 className='text-3xl  font-bold font-body mb-2 text-center'>Congrats, your verified!</h2></div>
				
            </section>
			) : (
				
				<div></div>) }
			
			<section className='flex flex-wrap justify-center'>
				
                {
                    NFTs ? NFTs.map(NFT => {
                       
                        return (
                           <NftCard image={NFT.media[0].gateway} id={NFT.id.tokenId } title={NFT.title} address={NFT.contract.address} description={NFT.description} attributes={NFT.metadata.attributes} ></NftCard>
                        )
                    }) : <div className='mb-8 text-white text-center text-3xl'>No NFTs found</div>
                }
				
            </section>
						<h3 className='hdnum'>3</h3>
						<h4 className='text-3xl mb-16 font-bold'>Enter and verify your Email</h4>
						<h3 className='hdnum'>4</h3>
						<h4 className='text-3xl mb-8 font-bold'>Download the Mobie App</h4>
						<div className='flex1'>
							<div className='btwhite10'><button onClick={() => openConnection()}><img src="./images/appstore.svg" alt="WalletConnect"/></button></div>
							<div className='btwhite10'><button onClick={() => openMetamask()}><img src="./images/googleplay.svg" alt="MetaMask"/></button></div>
						</div>
						<h4 className='text-3xl mb-16 font-bold'></h4>
						<h3 className='hdnum'>5</h3>
						<h4 className='text-3xl mb-16 font-bold'>Register with your verified email</h4>
						<h3 className='hdnum'>6</h3>
						<h4 className='text-3xl mb-16 font-bold'>Complete your verification</h4>
						<h3 className='hdnum'>7</h3>
						<h4 className='text-3xl mb-16 font-bold'>Earn $30 by taking action in the Mobie App</h4>
						<h3 className='hdnum'>8</h3>
						<h4 className='text-3xl mb-16 font-bold'>Earn reflections from Flex NFT Collabs</h4>
				 	</div>
				</div>
			</section>
			
			<section className='flex justify-center text-center'>
				<div className='flex flex-col items-center mb-12'>
				<img src="./images/arrow-down.png" width="80"></img>
				<div className='mb-6 text-white text-center sectitle'>
				<h2 className='text-3xl  font-bold font-body mb-2 imm2'>Once you’ve become part of the Flex Fam, you’ll be able to earn additional rewards and win prizes through our partnership with Mobie. From awesome NFTs to access passes, you will be able to earn points and redeem them.</h2>	
				</div>
				<div><button className='btwhite1' onClick={() => openConnection()}>WalletConnect</button></div>
				<p> </p>
				<div><button className='btwhite1' onClick={() => openMetamask()}>MetaMask</button></div>
				</div>
			</section>
			
			{ hasNFT ? (
			<section className='flex flex-wrap justify-center'>
				
				<div className='mb-16 text-white text-center'><h2 className='text-5xl  font-bold font-body mb-2 text-center'>Your Flex Vault is looking good Fam!</h2></div>
				
            </section>
			) : (
				
				<div></div>) }
			
			<section className='flex flex-wrap justify-center'>
				
                {
                    NFTs ? NFTs.map(NFT => {
                       
                        return (
                           <NftCard image={NFT.media[0].gateway} id={NFT.id.tokenId } title={NFT.title} address={NFT.contract.address} description={NFT.description} attributes={NFT.metadata.attributes} ></NftCard>
                        )
                    }) : <div className='mb-8 text-white text-center text-6xl'>No NFTs found</div>
                }
				
            </section>
			
			
			
			
			
			{ hasNFT ? (
			<section className='flex flex-wrap justify-center whiterborder'>
			<div className='mb-4 text-white text-center'><h2 className='text-5xl  font-bold font-body mb-2'>Submit the form to claim your FREE watches.</h2></div>
			<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd17-qSh32n2SRjaIi5d1BBcZsEDnJM4cNvlk8gqUW_2wiGRA/viewform?embedded=true" width="100%" height="1550" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
			</section>) : (
				
				<div></div>) }			
			
				
			<section className='flex flex-wrap justify-center'>
			<div className='text-center imm2'><img src="./images/squiggles.png" width="220"></img></div>
			</section>
			
        </div>

    )

	}


export default Explore